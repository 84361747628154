import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

import { Layout as SettingsLayout } from "@/pages/settings/layout";

const AccountPage = lazy(() => import("src/pages/settings/account/account"));
const TeamPage = lazy(() => import("src/pages/settings/team/team"));
const TenantDetailsPage = lazy(
  () => import("src/pages/settings/tenant/tenant")
);

const IntegrationsPage = lazy(
  () => import("src/pages/settings/integrations/integrations-page")
);
const CreateIntegrationPage = lazy(
  () => import("src/pages/settings/integrations/integration-create-page")
);
const IntegrationDetailsPage = lazy(
  () => import("src/pages/settings/integrations/integration-update-page")
);

export const settingRoutes: RouteObject[] = [
  {
    path: paths.settings.index,
    element: (
      <SettingsLayout>
        <AccountPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.account,
    element: (
      <SettingsLayout>
        <AccountPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.team,
    element: (
      <SettingsLayout>
        <TeamPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.integrations,
    element: (
      <SettingsLayout>
        <IntegrationsPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.createIntegration,
    element: (
      <SettingsLayout>
        <CreateIntegrationPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.configureIntegration,
    element: (
      <SettingsLayout>
        <IntegrationDetailsPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.tenant,
    element: (
      <SettingsLayout>
        <TenantDetailsPage />
      </SettingsLayout>
    ),
  },
];
