import { type FC, type ReactNode, useEffect } from "react";
import { useSessionStorageItem } from "@/utils/route";
import { withAuthGuard } from "@/hocs/with-auth-guard";
import GlobalStyles from "@mui/material/GlobalStyles";
import { Seo } from "@/components/seo";
import { SideNav } from "@/layouts/dashboard/layout/vertical-layout/side-nav";
import { useAuth } from "@/hooks/use-auth";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { WorksheetSwitch } from "@/pages/worksheet/components/worksheet-switch";
import { paths } from "@/paths";
import { StreamHandlersProvider } from "../task/context/chat-stream-context";
import { trpc } from "src/utils/trpc";
import type { WorksheetId } from "allgood-schema";

interface LayoutProps {
  children?: ReactNode;
  sidebar?: boolean;
}

export const Layout: FC<LayoutProps> = withAuthGuard(
  ({ sidebar = true, children }) => {
    const auth = useAuth();
    const params = useParams();

    const [worksheetId, setWorksheetId] = useSessionStorageItem("worksheetId");

    const { data: worksheet } = trpc.worksheet.getById.useQuery({
      id: worksheetId as WorksheetId,
    });

    useEffect(() => {
      if (params.worksheetId) {
        setWorksheetId(params.worksheetId);
      }
    }, [params]);

    return (
      <StreamHandlersProvider>
        <GlobalStyles
          styles={{
            body: {
              "--SideNav-width": sidebar ? "240px" : "0px",
            },
          }}
        />
        <Seo title="Worksheet" />
        {sidebar ? (
          <SideNav
            color={"discrete"}
            items={[
              {
                key: "worksheet",
                title: "",
                items: [
                  {
                    key: "items",
                    title: worksheet?.config.nounPlural ?? "Items",
                    href: paths.worksheets.items,
                    matcher: {
                      type: "startsWith",
                      href: paths.worksheets.items,
                    },
                  },
                  {
                    key: "flow",
                    title: "Flow",
                    href: paths.worksheets.worksheet,
                    matcher: {
                      type: "equals",
                      href: paths.worksheets.worksheet,
                    },
                  },
                  {
                    key: "tracing",
                    title: "Tracing",
                    href: paths.worksheets.worksheet,
                    matcher: {
                      type: "startsWith",
                      href: paths.guides.index,
                    },
                  },
                  {
                    key: "data",
                    title: "Data",
                    href: paths.worksheets.worksheet,
                    matcher: {
                      type: "startsWith",
                      href: paths.guides.index,
                    },
                  },
                  {
                    key: "settings",
                    title: "Settings",
                    href: paths.worksheets.settings,
                    matcher: {
                      type: "startsWith",
                      href: paths.worksheets.settings,
                    },
                  },
                ],
              },
            ]}
            auth={auth}
            topChildren={
              <Box
                sx={{
                  m: 2,
                  mb: 0,
                }}
              >
                <WorksheetSwitch />
              </Box>
            }
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            pl: "var(--SideNav-width)",
          }}
        >
          {children}
        </Box>
      </StreamHandlersProvider>
    );
  },
);
