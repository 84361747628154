import { forwardRef } from "react";
import { Link, type LinkProps } from "react-router-dom";

interface RouterLinkProps extends Omit<LinkProps, "to"> {
  href: string;
  style?: React.CSSProperties;
}

/**
 * This is an adapted for `react-router-dom/link` component.
 * We use this to help us maintain consistency between CRA and Next.js
 */
export const RouterLink = forwardRef(function RouterLink(
  props: RouterLinkProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any,
) {
  const { href, style, ...other } = props;

  return (
    <Link
      ref={ref}
      to={href}
      style={{
        textDecoration: "none",
        color: "inherit",
        ...style,
      }}
      {...other}
    />
  );
});
