export const paths = {
  index: "/",
  deals: {
    details: "/deals/:dealId",
  },
  auth: {
    login: "/login",
    loginCallback: "/code",
  },
  dashboard: {
    index: "/",
  },
  admin: {
    index: "/admin",
    overview: "/admin/overview",

    threads: {
      index: "/admin/threads",
      threadRef: "/admin/threads/:threadRefId",
    },

    takeout: {
      import: "/admin/takeout/import",
      export: "/admin/takeout/export",
    },
    buyerJourneys: "/admin/buyer-journeys",
    createBuyerJourney: "/admin/buyer-journeys/create",
    configureBuyerJourney: "/admin/buyer-journeys/:insightId",

    milestones: "/admin/milestones",
    createMilestones: "/admin/milestones/create",
    configureMilestones: "/admin/milestones/:milestoneId",

    businessObjectives: "/admin/business-objectives",
    configureBusinessObjectives:
      "/admin/business-objectives/:businessObjectiveId",
    createBusinessObjectives: "/admin/business-objectives/create",

    signals: "/admin/signals",
    createSignals: "/admin/signals/create",
    configureSignals: "/admin/signals/:signalId",

    /** Insights (human and ai) Related Admin **/
    insights: {
      index: "/admin/insights",
      create: "/admin/insights/create",
      configure: "/admin/insights/:insightId",
    },

    /** Audit Mission Related Admin **/
    audit: {
      checks: {
        index: "/admin/checks",
        create: "/admin/checks/create",
        configure: "/admin/checks/:checkId",
      },

      observations: {
        index: "/admin/observations",
        create: "/admin/observations/create/:checkId",
        configure: "/admin/observations/:observationId",
      },
    },

    /** Meta Data Related Admin **/
    metadata: {
      fields: {
        index: "/admin/metadata/fields",
        create: "/admin/metadata/fields/create",
        configure: "/admin/metadata/fields/:fieldId",
      },
      attributes: {
        index: "/admin/metadata/attributes",
        create: "/admin/metadata/attributes/create",
        configure: "/admin/metadata/attributes/:attributeId",
      },
      activityTypes: {
        index: "/admin/metadata/activity-types",
        configure: "/admin/metadata/activity-types/:activityTypeId",
      },
      marketoSync: {
        index: "/admin/metadata/sync",
      },
    },

    agents: "/admin/agents",
    createAgent: "/admin/agents/create",
    configureAgent: "/admin/agents/:agentId",

    agentWorkflows: "/admin/workflows",
    createAgentWorkflows: "/admin/workflows/create",
    configureAgentWorkflows: "/admin/workflows/:agentWorkflowId",

    workflows: "/data/workflows",
  },
  profile: {
    index: "/profile",
    switchTenant: "/switch-tenant",
    switchUserType: "/switch-role",
  },
  settings: {
    index: "/settings",

    account: "/settings/account",
    notifications: "/settings/notifications",
    security: "/settings/security",
    billing: "/settings/billing",
    team: "/settings/team",
    tenant: "/settings/tenant",

    integrations: "/settings/integrations",
    createIntegration: "/settings/integrations/create",
    configureIntegration: "/settings/integrations/:integrationId",
  },
  missions: {
    index: "/missions",
    configure: "/missions/:missionId/tasks",
    dashboard: "/missions/:missionId/dashboard",
    task: "/missions/:missionId/task/:taskId",
    companyDetail: "/missions/:missionId/company/:companyId",

    // Individual Mission Dashboards for missions can be added here
    // Routes in `routes/campaign.tsx` need to be reflected against this list
    dashboards: {
      insights: "/missions/:missionId/dashboard/insights",
      checks: "/missions/:missionId/dashboard/checks",
      journey: {
        overview: "/missions/:missionId/dashboard/overview",
        journeys: "/missions/:missionId/dashboard/journeys",
      },
      signals: {
        list: "/missions/:missionId/dashboard/signals",
      },
      dataPipeline: {
        overview: "/missions/:missionId/dashboard/dataPipeline",
      },
      dataNormalization: {
        overview: "/missions/:missionId/dashboard/dataNormalization",
      },
      attribution: {
        overview: "/missions/:missionId/dashboard/accountBasedAttribution",
      },
    },
  },
  worksheets: {
    index: "/worksheets",
    items: "/worksheets/:worksheetId/items",
    worksheet: "/worksheets/:worksheetId",
    chat: "/worksheets/:worksheetId/items/:itemId/steps/:stepId/chat",
    settings: "/worksheets/:worksheetId/settings",
  },
  labs: {
    lab: "/lab",
  },
  agents: {
    index: "/lab/agents",
    configure: "/lab/agents/:labId",
  },
  teams: {
    index: "/lab/teams",
    configure: "/lab/teams/:labId",
  },
  tools: {
    index: "/lab/tools",
    configure: "/lab/tools/:labId",
  },
  guides: {
    index: "/lab/guides",
    configure: "/lab/guides/:labId",
  },

  companies: {
    index: "/companies",
    companyDetail: "/companies/:companyId",
  },
  contacts: {
    index: "/contacts",
    contactDetail: "/contacts/:contactId",
  },
  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
} as const;
