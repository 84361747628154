import { useState, useEffect } from "react";

type IsParameter<Part> = Part extends `:${infer ParamName}` ? ParamName : never;
type FilteredParts<Path> = Path extends `${infer PartA}/${infer PartB}`
  ? IsParameter<PartA> | FilteredParts<PartB>
  : IsParameter<Path>;
type Params<Path> = {
  [Key in FilteredParts<Path>]: string;
};

export const buildRoute = <T extends string>(path: T, params: Params<T>) => {
  return path.replace(/:([^/]+)/g, (match, key: FilteredParts<T>) => {
    return params[key];
  });
};

/**
 * Subscribe to the value of a key from SessionStorage.
 *
 * (Claude wrote this --Will)
 */
export function useSessionStorageItem(
  key: string,
): [string | null, (value: string | null) => void] {
  // Initialize the state with the current value from sessionStorage
  const [value, setValue] = useState<string | null>(() => {
    return sessionStorage.getItem(key);
  });

  useEffect(() => {
    // Function to handle storage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea === sessionStorage && event.key === key) {
        setValue(event.newValue);
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key]);

  // Function to update the session storage item
  const setItem = (newValue: string | null) => {
    const oldValue = sessionStorage.getItem(key);

    if (newValue === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, newValue);
    }

    // Dispatch StorageEvent
    const event = new StorageEvent("storage", {
      key: key,
      oldValue: oldValue,
      newValue: newValue,
      storageArea: sessionStorage,
      url: window.location.href,
    });
    window.dispatchEvent(event);

    setValue(newValue);
  };

  return [value, setItem];
}
