import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const LabPage = lazy(() => import("src/pages/lab/lab-page"));
const AgentPage = lazy(() => import("src/pages/lab/agents/agents-page"));
const GuidePage = lazy(() => import("src/pages/lab/guides/guides-page"));
const TeamPage = lazy(() => import("src/pages/lab/teams/teams-page"));
const ToolPage = lazy(() => import("src/pages/lab/tools/tools-page"));
export const labsRoutes: RouteObject[] = [
  {
    path: paths.labs.lab,
    element: <LabPage />,
  },
  {
    path: paths.agents.index,
    element: <AgentPage />,
  },
  {
    path: paths.agents.configure,
    element: <AgentPage />,
  },
  {
    path: paths.guides.index,
    element: <GuidePage />,
  },
  {
    path: paths.guides.configure,
    element: <GuidePage />,
  },
  {
    path: paths.teams.index,
    element: <TeamPage />,
  },
  {
    path: paths.teams.configure,
    element: <TeamPage />,
  },
  {
    path: paths.tools.index,
    element: <ToolPage />,
  },
  {
    path: paths.tools.configure,
    element: <ToolPage />,
  },
];
