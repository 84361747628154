import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const WorksheetPage = lazy(() => import("src/pages/worksheet/worksheet-page"));
const WorksheetSettingsPage = lazy(
  () => import("src/pages/worksheet/settings-page"),
);
const WorksheetItemsPage = lazy(() => import("src/pages/worksheet/items-page"));

export const worksheetRoutes: RouteObject[] = [
  {
    path: paths.worksheets.worksheet,
    element: <WorksheetPage />,
  },
  {
    path: paths.worksheets.settings,
    element: <WorksheetSettingsPage />,
  },
  {
    path: paths.worksheets.items,
    element: <WorksheetItemsPage />,
  },
];
