import React, { createContext, useContext, useState } from "react";
import type { useStream } from "@/pages/task/hooks/useStream";

type StreamHandlersContextType = {
  streamHandlers: ReturnType<typeof useStream> | null;
  setStreamHandlers: React.Dispatch<
    React.SetStateAction<ReturnType<typeof useStream> | null>
  >;
  streamReadyHandler: (() => unknown) | null;
  setStreamReadyHandler: React.Dispatch<() => unknown>;
};

const StreamHandlersContext = createContext<
  StreamHandlersContextType | undefined
>(undefined);

export const StreamHandlersProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [streamHandlers, setStreamHandlers] = useState<ReturnType<
    typeof useStream
  > | null>(null);

  const [streamReadyHandler, setStreamReadyHandler] = useState<
    (() => unknown) | null
  >(null);

  return (
    <StreamHandlersContext.Provider
      value={{
        streamHandlers,
        setStreamHandlers,
        streamReadyHandler,
        setStreamReadyHandler,
      }}
    >
      {children}
    </StreamHandlersContext.Provider>
  );
};

export const useStreamHandlers = (): StreamHandlersContextType => {
  const context = useContext(StreamHandlersContext);
  if (!context) {
    throw new Error(
      "useStreamHandlers must be used within a StreamHandlersProvider",
    );
  }
  return context;
};
