import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import App from "./App";
import { routes } from "@/routes";
import { ScrollRestoration } from "./components/scroll-restoration";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App>
        <ScrollRestoration />
        <Outlet />
      </App>
    ),
    children: [...routes],
    hasErrorBoundary: true,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
