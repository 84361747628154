import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const CompanyListPage = lazy(
  () => import("src/pages/company-list/company-list-page")
);
const CompanyPage = lazy(() => import("src/pages/company/company-page"));

export const companyRoutes: RouteObject[] = [
  {
    path: paths.companies.index,
    element: <CompanyListPage />,
  },
  {
    path: paths.companies.companyDetail,
    element: <CompanyPage />,
  },
];
