import { createContext } from "react";

import type { Settings } from "../../types/settings";
import { config } from "@/config";

export const defaultSettings: Settings = {
  colorScheme: config.site.colorScheme,
  primaryColor: config.site.primaryColor,
  direction: "ltr",
  navColor: "discrete",
  layout: "vertical",
  stretch: false,
  debugMode: false,
  sideNavCollapsed: true,
};

export interface State extends Settings {
  openDrawer: boolean;
  isInitialized: boolean;
}

export const initialState: State = {
  ...defaultSettings,
  isInitialized: false,
  openDrawer: false,
};

export interface SettingsContextType extends State {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  handleReset: () => void;
  handleUpdate: (settings: Settings) => void;
  toggleDebugMode: () => void;
  toggleSideNav: () => void;
  isCustom: boolean;
}

export const SettingsContext = createContext<SettingsContextType>({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  toggleDebugMode: () => {},
  toggleSideNav: () => {},
  isCustom: false,
});
