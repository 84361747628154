import type { FC } from "react";

const HEIGHT = 50;
const WIDTH = 150;

export interface LogoProps {
  type?: "light" | "dark";
  height?: number;
  width?: number;
}

export const Logo: FC<LogoProps> = ({
  type = "light",
  height = HEIGHT,
  width = WIDTH,
}) => {
  const logo =
    type === "light"
      ? "/assets/allgood-logo-on-white-color-horz.svg"
      : "/assets/allgood-logo-on-black-color-horz-2.svg";
  return <img src={logo} width={width} height={height}></img>;
};
