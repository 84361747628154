"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import type { NavItemConfig } from "@/types/nav";
import { AccountButton } from "@/layouts/dashboard/account-button";
import { RouterLink } from "@/components/core/router-link";
import { paths } from "@/paths";
import { Logo } from "@/components/core/logo";
import type { State } from "@/contexts/auth/jwt/auth-context";
import Typography from "@mui/material/Typography";
import { isNavItemActive } from "@/lib/is-nav-item-active";
import { icons } from "@/layouts/dashboard/layout/nav-icons";
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { CaretRight as CaretRightIcon } from "@phosphor-icons/react/dist/ssr/CaretRight";
import Chip from "@mui/material/Chip";
import { ArrowSquareOut as ArrowSquareOutIcon } from "@phosphor-icons/react/dist/ssr/ArrowSquareOut";
import { usePathname } from "@/hooks/use-pathname";
import { useAuth } from "@/hooks/use-auth";
import { agPink } from "@/styles/theme/colors";
import { generatePath, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useSessionStorageItem } from "@/utils/route";

export interface MainNavProps {
  items: NavItemConfig[];
}

export function MainNav({ items }: MainNavProps): React.JSX.Element {
  const pathname = usePathname();
  const auth = useAuth();

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          "--MainNav-background": agPink[500],
          "--MainNav-divider": "var(--mui-palette-divider)",
          bgcolor: "var(--MainNav-background)",
          color: "var(--mui-palette-neutral-100)",
          left: 0,
          right: 0,
          position: "fixed",
          pt: { lg: "var(--Layout-gap)" },
          top: 0,
          height: "var(--MainNav-height)",
          zIndex: "var(--MainNav-zIndex)",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid var(--MainNav-divider)",
            display: "flex",
            flex: "1 1 auto",
            minHeight: "var(--MainNav-height)",
            px: { xs: 2, lg: 3 },
          }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Box
              component={RouterLink}
              href={paths.index}
              sx={{ display: "inline-flex" }}
              reloadDocument
            >
              <Logo type={"dark"} />
            </Box>
          </Stack>
          <Box
            component="nav"
            sx={{
              flex: "1 1 auto",
              overflowY: "auto",
              // justifyItems: "end",
              p: 2,
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {renderNavGroups({ items, pathname, auth })}
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              // flex: "1 1 auto",
              justifyContent: "flex-end",
            }}
          >
            {/*<Divider*/}
            {/*  flexItem*/}
            {/*  orientation="vertical"*/}
            {/*  sx={{*/}
            {/*    borderColor: "var(--MainNav-divider)",*/}
            {/*    display: { xs: "none", lg: "block" },*/}
            {/*  }}*/}
            {/*/>*/}
            <AccountButton />
          </Stack>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function renderNavGroups({
  items,
  pathname,
  auth,
}: {
  items: NavItemConfig[];
  pathname: string;
  auth?: State;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      if (
        curr.allowedRoles !== undefined &&
        !curr.allowedRoles?.includes(auth?.user?.userRole ?? "STANDARD") &&
        auth?.user?.userType !== "SUPERUSER"
      ) {
        return acc;
      }
      if (
        curr.allowedTypes !== undefined &&
        !curr.allowedTypes?.includes(auth?.user?.userType ?? "USER")
      ) {
        return acc;
      }

      acc.push(
        <Stack component="li" key={curr.key} spacing={1.5} alignItems={"end"}>
          {curr.title ? (
            <div>
              <Typography
                sx={{
                  color: "var(--NavGroup-title-color)",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                }}
              >
                {curr.title}
              </Typography>
            </div>
          ) : null}
          <div>
            {renderNavItems({ depth: 0, items: curr.items, pathname, auth })}
          </div>
        </Stack>,
      );

      return acc;
    },
    [],
  );

  return (
    <Stack component="ul" spacing={2} sx={{ listStyle: "none", m: 0, p: 0 }}>
      {children}
    </Stack>
  );
}

function renderNavItems({
  depth = 0,
  items = [],
  pathname,
  auth,
}: {
  depth: number;
  items?: NavItemConfig[];
  pathname: string;
  auth?: State;
}): React.JSX.Element {
  const children = items.reduce(
    (acc: React.ReactNode[], curr: NavItemConfig): React.ReactNode[] => {
      const { items: childItems, key, ...item } = curr;

      const forceOpen = childItems
        ? Boolean(
            childItems.find(
              (childItem) =>
                childItem.href && pathname.startsWith(childItem.href),
            ),
          )
        : false;

      if (
        curr.allowedRoles !== undefined &&
        !curr.allowedRoles?.includes(auth?.user?.userRole ?? "STANDARD") &&
        auth?.user?.userType !== "SUPERUSER"
      ) {
        return acc;
      }
      if (
        curr.allowedTypes !== undefined &&
        !curr.allowedTypes?.includes(auth?.user?.userType ?? "USER")
      ) {
        return acc;
      }

      acc.push(
        <NavItem
          depth={depth}
          forceOpen={forceOpen}
          key={key}
          pathname={pathname}
          {...item}
        >
          {childItems
            ? renderNavItems({
                depth: depth + 1,
                pathname,
                items: childItems,
                auth,
              })
            : null}
        </NavItem>,
      );

      return acc;
    },
    [],
  );

  return (
    <Stack
      component="ul"
      direction={"row"}
      data-depth={depth}
      spacing={1}
      sx={{ listStyle: "none", m: 0, p: 0 }}
    >
      {children}
    </Stack>
  );
}

interface NavItemProps extends Omit<NavItemConfig, "items"> {
  children?: React.ReactNode;
  depth: number;
  forceOpen?: boolean;
  pathname: string;
}

function NavItem({
  children,
  depth,
  disabled,
  external,
  forceOpen = false,
  href,
  icon,
  label,
  matcher,
  pathname,
  title,
  tooltip,
}: NavItemProps): React.JSX.Element {
  const params = useParams();
  const [open, setOpen] = React.useState<boolean>(forceOpen);

  const [sessionWorksheetId] = useSessionStorageItem("worksheetId");
  const worksheetId = params.worksheetId ?? sessionWorksheetId;

  const path = worksheetId
    ? generatePath(href ?? "", { ...params, worksheetId })
    : href;
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname,
    params,
  });
  const Icon = icon ? icons[icon] : null;
  const ExpandIcon = open ? CaretDownIcon : CaretRightIcon;
  const isBranch = children && !href;
  const showChildren = Boolean(children && open);

  return (
    <Box component="li" data-depth={depth} sx={{ userSelect: "none" }}>
      <Tooltip title={tooltip}>
        <Box
          {...(isBranch
            ? {
                onClick: (): void => {
                  setOpen(!open);
                },
                onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>): void => {
                  if (event.key === "Enter" || event.key === " ") {
                    setOpen(!open);
                  }
                },
                role: "button",
              }
            : {
                ...(path
                  ? {
                      component: external ? "a" : RouterLink,
                      href: path,
                      target: external ? "_blank" : undefined,
                      rel: external ? "noreferrer" : undefined,
                    }
                  : { role: "button" }),
              })}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            color: "var(--NavItem-color)",
            cursor: "pointer",
            display: "flex",
            flex: "0 0 auto",
            gap: 1,
            p: "6px 16px",
            height: "42px",
            position: "relative",
            textDecoration: "none",
            whiteSpace: "nowrap",
            ...(disabled && {
              bgcolor: "var(--NavItem-disabled-background)",
              color: "var(--NavItem-disabled-color)",
              cursor: "not-allowed",
            }),
            ...(active && {
              bgcolor: "var(--NavItem-active-background)",
              color: "var(--NavItem-active-color) !important",
              ...(depth > 0 && {
                "&::before": {
                  bgcolor: "var(--NavItem-children-indicator)",
                  borderRadius: "2px",
                  content: '" "',
                  height: "20px",
                  left: "-14px",
                  position: "absolute",
                  width: "3px",
                },
              }),
            }),
            ...(open && { color: "var(--NavItem-open-color)" }),
            "&:hover": {
              ...(!disabled &&
                !active && {
                  bgcolor: "var(--NavItem-hover-background)",
                  color: "var(--NavItem-hover-color) !important",
                }),
            },
          }}
          tabIndex={0}
        >
          {Icon ? (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flex: "0 0 auto",
              }}
            >
              <Icon
                fill={
                  active
                    ? "var(--NavItem-icon-active-color)"
                    : "var(--NavItem-icon-color)"
                }
                fontSize="var(--icon-fontSize-md)"
                weight={forceOpen || active ? "fill" : undefined}
              />
            </Box>
          ) : null}
          {title && (
            <Box sx={{ flex: "1 1 auto" }}>
              <Typography
                component="span"
                sx={{
                  color: "inherit",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  lineHeight: "28px",
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          {label ? <Chip color="primary" label={label} size="small" /> : null}
          {external ? (
            <Box
              sx={{ alignItems: "center", display: "flex", flex: "0 0 auto" }}
            >
              <ArrowSquareOutIcon
                color="var(--NavItem-icon-color)"
                fontSize="var(--icon-fontSize-sm)"
              />
            </Box>
          ) : null}
          {isBranch ? (
            <Box
              sx={{ alignItems: "center", display: "flex", flex: "0 0 auto" }}
            >
              <ExpandIcon
                color="var(--NavItem-expand-color)"
                fontSize="var(--icon-fontSize-sm)"
              />
            </Box>
          ) : null}
        </Box>
      </Tooltip>
      {showChildren ? (
        <Box sx={{ pl: "24px" }}>
          <Box
            sx={{
              borderLeft: "1px solid var(--NavItem-children-border)",
              pl: "12px",
            }}
          >
            {children}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
