import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

/**
 * Send an event to Amplitude analytics. This function is a wrapper around the Amplitude SDK.
 * @param event The event to send to Amplitude. This can be a string or an object.
 * @see https://amplitude.github.io/Amplitude-TypeScript/interfaces/_amplitude_analytics_browser.Types.BaseEvent.html
 */
export function sendEventToAmplitude(
  event: string | amplitude.Types.BaseEvent,
) {
  amplitude.track(event);
}

/**
 * Identify the user in Amplitude. This function is a wrapper around the Amplitude SDK.
 * @param userId the user ID to identify in Amplitude.
 */
export function identifyUser(userId: string) {
  amplitude.setUserId(userId);
}

/**
 * Initialize the Amplitude SDK with the provided API key.
 * @param key The Amplitude API key to initialize the Amplitude SDK.
 */
export function initAmplitude(key: string) {
  amplitude.init(key);
}

/**
 * Initialize the Amplitude Session Replay plugin. This function is a wrapper around the Amplitude SDK.
 */
export function initAmplitudeSessionReplay() {
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);
}
