import type { RouterOutputs } from "src/utils/trpc";

export enum Issuer {
  JWT = "JWT",
}

export const checkIsAllgoodUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.email.endsWith("@allgoodhq.com");

export const checkIsSuperUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.userType === "SUPERUSER";

export const checkIsAdmin = (user?: RouterOutputs["auth"]["me"]) =>
  checkIsSuperUser(user) || user?.userRole === "ADMIN";
