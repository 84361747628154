import type { NavItemConfig } from "@/types/nav";
import { generatePath } from "react-router-dom";
import type { Params } from "react-router";

export function isNavItemActive({
  disabled,
  external,
  href,
  matcher,
  pathname,
  params,
}: Pick<NavItemConfig, "disabled" | "external" | "href" | "matcher"> & {
  pathname: string;
  params?: Params<string>;
}): boolean {
  if (disabled || !href || external) {
    return false;
  }

  let matchHref = href;
  try {
    matchHref = generatePath(matcher?.href ?? "/", params);
    href = generatePath(href, params);
  } catch (_e) {
    // Do nothing if the path is invalid instead of logging
  }

  if (matcher) {
    if (matcher.type === "startsWith") {
      return pathname.startsWith(matchHref);
    }

    if (matcher.type === "equals") {
      return pathname === matchHref;
    }

    return false;
  }

  return pathname === href;
}
