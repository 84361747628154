import { Backdrop, Box, CircularProgress } from "@mui/material";
import type { FunctionComponent, PropsWithChildren } from "react";

import Error500Page from "../pages/500";

type LoadingProps = {
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
  size?: number | string;
  fullscreen?: boolean;
  thickness?: number;
};

const Loading: FunctionComponent<PropsWithChildren<LoadingProps>> = ({
  loading,
  error,
  fullscreen = true,
  sx,
  size,
  thickness,
  children,
}) => {
  if (error) {
    return <Error500Page error={error} />;
  }
  return (
    <>
      {loading ? (
        fullscreen ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Box
            component={"div"}
            sx={{ textAlign: "center", ...sx, width: "100%", height: "100%" }}
            position={"relative"}
          >
            <CircularProgress
              color="inherit"
              size={size}
              thickness={thickness}
            />
          </Box>
        )
      ) : (
        children
      )}
    </>
  );
};

export default Loading;
