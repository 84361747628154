import "@/styles/global.css";
import "@/styles/markdown.css";
import "simplebar-react/dist/simplebar.min.css";
import "@uploadcare/react-uploader/core.css";
import { Analytics } from "@/components/core/analytics";
import { Toaster } from "./components/toaster";
import * as config from "./config";
import { AuthProvider } from "./contexts/auth/jwt";
import { SettingsProvider } from "./contexts/settings";
import { useNprogress } from "./hooks/use-nprogress";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { trpc } from "src/utils/trpc";
import { httpLink, loggerLink } from "@trpc/client";
import superjson from "superjson";
import { type FC, type PropsWithChildren, useMemo } from "react";
import { ThemeProvider } from "@/components/core/theme-provider/theme-provider";
import { LocalizationProvider } from "@/components/core/localization-provider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import type { Metadata } from "@/types/metadata";
import { ErrorBoundary } from "react-error-boundary";
import page500 from "./pages/500";
import { initAmplitude, initAmplitudeSessionReplay } from "./utils/amplitude";
import { initSentry } from "./utils/sentry";

const metadata: Metadata = { title: config.config.site.name };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 300 * 60 * 1000,
      cacheTime: 600 * 60 * 1000,
      networkMode: "offlineFirst",
    },
  },
  queryCache: new QueryCache({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: async (error: any, _query) => {
      if (error.status === 403) {
        console.log("Logging out");
        localStorage.setItem("RETURN_TO", window.location.pathname);
        window.location.href = `/login`;
      }
    },
  }),
});

// Setup Amplitude
initAmplitudeSessionReplay();
initAmplitude(config.AMPLITUDE_TRACKING_ID);

// Setup Sentry
initSentry();

const App: FC<PropsWithChildren> = ({ children }) => {
  // REMOVE GTM FOR NOW useAnalytics(gtmConfig);
  useNprogress();

  // TODO: Fix this, its backwards, it should also read from AuthContext.
  const trpcClient = useMemo(() => {
    return trpc.createClient({
      links: [
        loggerLink({
          enabled: (opts) =>
            config.DEV ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpLink({
          url: `${config.API_SERVER}/api/trpc`,
          headers() {
            const accessToken = window.localStorage.getItem("accessToken");
            return {
              authorization: accessToken ? `Bearer ${accessToken}` : "",
            };
          },
        }),
      ],
      transformer: superjson,
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metadata.title}</title>
      </Helmet>
      <ErrorBoundary fallbackRender={page500}>
        <trpc.Provider queryClient={queryClient} client={trpcClient}>
          <QueryClientProvider client={queryClient}>
            <Analytics>
              <LocalizationProvider>
                <AuthProvider>
                  <SettingsProvider>
                    <ThemeProvider>
                      {children}
                      <Toaster />
                    </ThemeProvider>
                  </SettingsProvider>
                </AuthProvider>
              </LocalizationProvider>
            </Analytics>
          </QueryClientProvider>
        </trpc.Provider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
