import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const AdminPage = lazy(() => import("@/pages/admin/index"));

const TakeoutImportPage = lazy(() => import("@/pages/admin/takeout/import"));
const TakeoutExportPage = lazy(() => import("@/pages/admin/takeout/export"));

const BusinessObjectivesPage = lazy(
  () => import("@/pages/admin/business-objective/business-objectives-page"),
);
const BusinessObjectivesDetailsPage = lazy(
  () =>
    import("@/pages/admin/business-objective/business-objective-details-page"),
);
const BusinessObjectivesCreatePage = lazy(
  () =>
    import("@/pages/admin/business-objective/business-objective-create-page"),
);

const AgentWorkflowPage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-page"),
);
const AgentWorkflowCreatePage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-create-page"),
);
const AgentWorkflowDetailsPage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-details-page"),
);
const WorkflowPage = lazy(() => import("@/pages/admin/workflows"));

/** Meta Data Pages **/

const MetaDataAttributesPage = lazy(
  () => import("@/pages/admin/metadata/attributes/attributes-page"),
);

const MetaDataAttributeConfigurePage = lazy(
  () => import("@/pages/admin/metadata/attributes/attributes-details-page"),
);

const MetaDataActivityTypesPage = lazy(
  () => import("@/pages/admin/metadata/activity-types/activity-types-page"),
);

const MetaDataActivityTypeConfigurePage = lazy(
  () =>
    import("@/pages/admin/metadata/activity-types/activity-types-details-page"),
);

const MarketoSyncPage = lazy(
  () => import("src/pages/admin/metadata/marketo-sync/sync"),
);

const ThreadListPage = lazy(() => import("@/pages/admin/threads/threads-list"));
const ThreadRefChatPage = lazy(
  () => import("@/pages/admin/threads/thread-ref-chat"),
);

export const adminRoutes: RouteObject[] = [
  {
    path: paths.admin.overview,
    element: <AdminPage />,
  },
  {
    path: paths.admin.takeout.import,
    element: <TakeoutImportPage />,
  },
  {
    path: paths.admin.takeout.export,
    element: <TakeoutExportPage />,
  },
  {
    path: paths.admin.businessObjectives,
    element: <BusinessObjectivesPage />,
  },
  {
    path: paths.admin.createBusinessObjectives,
    element: <BusinessObjectivesCreatePage />,
  },
  {
    path: paths.admin.configureBusinessObjectives,
    element: <BusinessObjectivesDetailsPage />,
  },
  {
    path: paths.admin.agentWorkflows,
    element: <AgentWorkflowPage />,
  },
  {
    path: paths.admin.createAgentWorkflows,
    element: <AgentWorkflowCreatePage />,
  },
  {
    path: paths.admin.configureAgentWorkflows,
    element: <AgentWorkflowDetailsPage />,
  },
  {
    path: paths.admin.workflows,
    element: <WorkflowPage />,
  },

  /** Meta Data Pages **/
  {
    path: paths.admin.metadata.attributes.index,
    element: <MetaDataAttributesPage />,
  },
  {
    path: paths.admin.metadata.attributes.create,
    element: <MetaDataAttributeConfigurePage />,
  },
  {
    path: paths.admin.metadata.attributes.configure,
    element: <MetaDataAttributeConfigurePage />,
  },
  {
    path: paths.admin.metadata.activityTypes.index,
    element: <MetaDataActivityTypesPage />,
  },
  {
    path: paths.admin.metadata.activityTypes.configure,
    element: <MetaDataActivityTypeConfigurePage />,
  },
  {
    path: paths.admin.metadata.marketoSync.index,
    element: <MarketoSyncPage />,
  },
  {
    path: paths.admin.threads.index,
    element: <ThreadListPage />,
  },
  {
    path: paths.admin.threads.threadRef,
    element: <ThreadRefChatPage />,
  },
];
