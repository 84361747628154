import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "../layouts/dashboard/layout/layout";

import { authRoutes } from "./auth";
import { adminRoutes } from "src/routes/admin";
import { settingRoutes } from "src/routes/settings";
import { integrationOAuthRoutes } from "src/routes/integration-oauth";
import Loading from "@/components/loading";
import { paths } from "@/paths";
import { worksheetRoutes } from "@/routes/worksheets";
import { profileRoutes } from "@/routes/profile";
import { companyRoutes } from "@/routes/company";
import { labsRoutes } from "@/routes/labs";
import { Layout as LabLayout } from "@/pages/lab/layout";
import { Layout as WorksheetLayout } from "@/pages/worksheet/layout";
import { StreamHandlersProvider } from "src/pages/task/context/chat-stream-context";

const Error401Page = lazy(() => import("../pages/401"));
const Error404Page = lazy(() => import("../pages/404"));
const Error500Page = lazy(() => import("../pages/500"));

const WorksheetListPage = lazy(
  () => import("src/pages/worksheet-list/worksheet-list-page"),
);
const ChatPage = lazy(() => import("src/pages/worksheet/chat-page"));

// Insights
export const routes: RouteObject[] = [
  {
    element: (
      <DashboardLayout>
        <Suspense fallback={<Loading loading={true} />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "/",
        element: (
          <StreamHandlersProvider>
            <WorksheetListPage />
          </StreamHandlersProvider>
        ),
      },
      {
        path: paths.worksheets.worksheet,
        element: (
          <WorksheetLayout>
            <Outlet />
          </WorksheetLayout>
        ),
        children: [...worksheetRoutes],
      },
      {
        path: paths.worksheets.chat,
        element: (
          <WorksheetLayout sidebar={false}>
            <ChatPage />
          </WorksheetLayout>
        ),
      },
      {
        path: paths.labs.lab,
        element: (
          <LabLayout>
            <Outlet />
          </LabLayout>
        ),
        children: [...labsRoutes],
      },

      ...adminRoutes,
      ...settingRoutes,
      ...profileRoutes,
      ...companyRoutes,
    ],
  },
  ...integrationOAuthRoutes,

  ...authRoutes,
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page error={undefined} />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
