import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { GuestGuard } from "src/guards/guest-guard";
import { Layout as AuthLayout } from "src/layouts/auth/modern-layout";

const LoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const LoginCallbackPage = lazy(() => import("src/pages/auth/jwt/code"));
export const authRoutes: RouteObject[] = [
  {
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "code",
        element: <LoginCallbackPage />,
      },
    ],
  },
];
