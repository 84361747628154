import {
  DocumentScannerOutlined,
  StarOutlineOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { type CreateWorksheet } from "allgood-api/src/repos/worksheet.schema";
import { type WorksheetId } from "allgood-schema";
import { useEffect, useState } from "react";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { agPink } from "src/styles/theme/colors";
import { buildRoute } from "src/utils/route";
import { trpc } from "src/utils/trpc";

export function WorksheetCreateDialog({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit?: () => void;
}) {
  const router = useRouter();

  const [worksheet, setWorksheet] = useState<CreateWorksheet>({
    name: "",
    ident: "",
    description: "",
    tags: [],
    config: {
      customLabel: {},
    },
  });
  const [referenceWorksheetId, setReferenceWorksheetId] =
    useState<WorksheetId | null>(null);
  const [alert, setAlert] = useState("");
  const [flow, setFlow] = useState<"create" | "copy" | null>(null);

  // useEffect that clears state on close.
  useEffect(() => {
    if (!open) {
      setWorksheet({
        name: "",
        ident: "",
        description: "",
        tags: [],
        config: {
          customLabel: {},
        },
      });
      setReferenceWorksheetId(null);
      setAlert("");
      setFlow(null);
    }
  }, [open]);

  const { data: worksheets } = trpc.worksheet.list.useQuery({
    limit: 100,
    offset: 0,
    filter: {
      config: {
        isTemplate: true,
      },
    },
  });
  const { mutateAsync: createWorksheet } = trpc.worksheet.create.useMutation();
  const { mutateAsync: cloneWorksheet } =
    trpc.worksheet.cloneWorksheet.useMutation();

  const trpcUtils = trpc.useUtils();

  const handleCreateNewWorksheet = async () => {
    try {
      let res = null;
      if (flow === "copy") {
        if (!referenceWorksheetId || !worksheet.name) {
          throw new Error("Please select a worksheet to copy");
        }
        res = await cloneWorksheet({
          worksheetId: referenceWorksheetId,
          newName: worksheet.name,
        });
      } else {
        if (!worksheet.name) {
          throw new Error("Please enter a name for the worksheet");
        }
        res = await createWorksheet(worksheet);
      }
      trpcUtils.worksheet.list.invalidate();
      setOpen(false);

      // Call the submit handler, if set.
      if (onSubmit) {
        onSubmit();
      }

      // Navigate to the new worksheet.
      router.push(
        buildRoute(paths.worksheets.worksheet, { worksheetId: res.id }),
      );
    } catch (e: unknown) {
      setAlert((e as Error).message);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box sx={{ m: 2 }}>
        <DialogTitle>Create Worksheet</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <TextField
              label="Name"
              value={worksheet.name}
              onChange={(e) =>
                setWorksheet({
                  ...worksheet,
                  name: e.target.value,
                  ident: e.target.value,
                })
              }
              fullWidth
            />

            <Stack direction="row" gap={1}>
              <OnboardingButton
                title="Start From Scratch"
                icon={<StarOutlineOutlined />}
                onClick={() => {
                  setFlow("create");
                }}
                active={flow === "create"}
              />
              <OnboardingButton
                title="Copy From Existing"
                icon={<DocumentScannerOutlined />}
                onClick={() => {
                  setFlow("copy");
                }}
                active={flow === "copy"}
              />
            </Stack>

            {flow === "copy" && (
              <Stack direction={"column"} spacing={1}>
                <Typography variant="body2">Copy from worksheet</Typography>
                <Autocomplete
                  options={worksheets?.records ?? []}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    if (value) {
                      setWorksheet({
                        ...worksheet,
                        config: value.config,
                      });
                    }
                    setReferenceWorksheetId(value?.id ?? null);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </Stack>
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateNewWorksheet}
            disabled={
              !worksheet.name ||
              !worksheet.config ||
              !flow ||
              (flow === "copy" && !referenceWorksheetId)
            }
          >
            Create
          </Button>
        </DialogActions>
      </Box>

      <Alert severity="error" sx={{ mt: 2, display: alert ? "block" : "none" }}>
        {alert}
      </Alert>
    </Dialog>
  );
}

export const OnboardingButton = ({
  title,
  icon,
  onClick,
  active,
}: {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  active: boolean;
}) => {
  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      flexGrow={1}
      spacing={1}
      sx={{
        cursor: "pointer",
        p: 2,
        mb: 1,
        color: agPink[600],
        borderRadius: 1,
        border: "1px solid",
        transition: "all 0.2s",
        "&:hover": active
          ? {}
          : {
              color: agPink[500],
              bgcolor: "background.level1",
            },
        ...(active ? { color: "white", bgcolor: agPink[600] } : {}),
      }}
      onClick={onClick}
    >
      {icon}
      <Typography variant="body2">{title}</Typography>
    </Stack>
  );
};
