import type { FC } from "react";
import toast, { Toaster as HotToaster } from "react-hot-toast";
import { alpha } from "@mui/system/colorManipulator";
import { useTheme } from "@mui/material/styles";
export const Toaster: FC = () => {
  const theme = useTheme();

  return (
    <HotToaster
      position="bottom-right"
      toastOptions={{
        style: {
          backdropFilter: "blur(6px)",
          background: alpha(theme.palette.neutral[900], 0.8),
          color: theme.palette.common.white,
          boxShadow: theme.shadows[16],
        },
      }}
    />
  );
};

/**
 * Utility method to get a toast with a Mary logo in it.
 * TODO: ALL-2571 Ibrahim to make the icon prettier
 */
export function showLoadingToast(
  message: string,
  duration: number = 5000,
  iconUrl: string = "https://cdn.prod.website-files.com/6537ecf8dd6837236ee1b763/671928c38a76da7c1f729de7_maryv2-p-500.webp",
) {
  return toast.loading(message, {
    duration,
    icon: (
      <img src={iconUrl} alt="icon" style={{ width: "50px", height: "50px" }} />
    ),
  });
}
