import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

// Create a generic callback page component
const OAuthCallbackPage = lazy(() => import("src/pages/auth/oauth-callback"));

export const integrationOAuthRoutes: RouteObject[] = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    path: "oauth2",
    children: [
      {
        // Use a URL parameter :service to capture the service name
        path: ":service/callback",
        element: <OAuthCallbackPage />,
      },
    ],
  },
];
