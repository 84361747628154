import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Seo } from "src/components/seo";
import { paths } from "src/paths";

// eslint-disable-next-line no-empty-pattern
const Page = ({}: { error?: Error }) => {
  return (
    <>
      <Seo title="Error: Server Error" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={"h3"}>
            500: Internal Server Error
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            Unexpected error occurred. Please try again later.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button href={paths.index}>Back to Home</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
