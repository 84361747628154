"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretUpDown as CaretUpDownIcon } from "@phosphor-icons/react/dist/ssr/CaretUpDown";
import { type Worksheet } from "allgood-api/src/repos/worksheet.schema";
import { usePopover } from "@/hooks/use-popover";
import { WorksheetPopover } from "./worksheet-popover";
import { trpc } from "@/utils/trpc";
import type { WorksheetId } from "allgood-schema";
import { buildRoute } from "@/utils/route";
import { paths } from "@/paths";
import { useParams } from "react-router-dom";

export function WorksheetSwitch(): React.JSX.Element {
  const { worksheetId } = useParams();
  const popover = usePopover<HTMLDivElement>();
  const [worksheets] = trpc.worksheet.list.useSuspenseQuery({ filter: {} });
  const worksheet: Worksheet | undefined =
    worksheets.records.find((w) => w.id === worksheetId) ??
    worksheets.records[0];

  if (!worksheet) {
    throw new Error("No worksheets found in WorksheetSwitch");
  }

  const onSelect = (id: WorksheetId) => {
    location.pathname = buildRoute(paths.worksheets.worksheet, {
      worksheetId: id,
    });
  };

  return (
    <React.Fragment>
      <Stack
        direction="row"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        spacing={2}
        sx={{
          alignItems: "center",
          // backgroundColor: "white",
          border: "1px solid #DDD",
          borderRadius: "12px",
          // color: "var(--Workspaces-text-color)",
          cursor: "pointer",
          p: "4px 8px",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }}>
          <Typography variant="caption">Worksheet</Typography>
          <Typography variant="subtitle2">{worksheet.name}</Typography>
        </Box>
        <CaretUpDownIcon
        // color="var(--Workspaces-expand-color)"
        // fontSize="var(--icon-fontSize-sm)"
        />
      </Stack>
      <WorksheetPopover
        selectedWorksheet={worksheet}
        anchorEl={popover.anchorRef.current}
        onChange={(id: WorksheetId) => {
          onSelect(id);
          popover.handleClose();
        }}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </React.Fragment>
  );
}
