import type { ColorScheme, PrimaryColor } from "@/styles/theme/types";
import { LogLevel } from "./lib/logger";

/** The Vite environment mode the app is running in. */
export const MODE: string = import.meta.env.MODE;

/** True if the app is running in a production mode. */
export const PROD: boolean = import.meta.env.PROD;

/** True if the app is running in a development mode. */
export const DEV: boolean = import.meta.env.DEV;

/** The API server the application is using. */
export const API_SERVER: string = import.meta.env.VITE_API_SERVER;

export const TEMPORAL_CLOUD_URL: string =
  import.meta.env.VITE_TEMPORAL_CLOUD_URL ??
  "http://localhost:8080/namespaces/default";

export const UPLOADCARE_PUBLIC_KEY: string = import.meta.env
  .VITE_UPLOADCARE_PUBLIC_KEY;

export const AMPLITUDE_TRACKING_ID: string = import.meta.env
  .VITE_AMPLITUDE_TRACKING_ID;

export const MOMENTO_CACHE_NAME: string = import.meta.env
  .VITE_MOMENTO_CACHE_NAME;

// Log the app's configuration to the console.
console.group("App Configuration");
console.log("MODE:", MODE);
console.log("PROD:", PROD);
console.log("DEV:", DEV);
console.log("API_SERVER:", API_SERVER);
console.log("AMPLITUDE_TRACKING_ID:", AMPLITUDE_TRACKING_ID);
console.groupEnd();

if (!API_SERVER) {
  throw new Error(
    "The VITE_API_SERVER environment variable is not set. Please set it in your .env file.",
  );
}

export interface Config {
  site: {
    name: string;
    description: string;
    colorScheme: ColorScheme;
    primaryColor: PrimaryColor;
    themeColor: string;
    url: string;
    version: string;
  };
  logLevel: keyof typeof LogLevel;
  gtm?: { id?: string };
}

export const config = {
  site: {
    name: "allGood",
    description: "",
    colorScheme: "light",
    themeColor: "#DC4393",
    primaryColor: "allgood",
    url: location.origin,
    version: import.meta.env.VITE_SITE_VERSION || "0.0.0",
  },
  logLevel:
    (import.meta.env.VITE_LOG_LEVEL as keyof typeof LogLevel) || LogLevel.ALL,
  gtm: { id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID },
} satisfies Config;
