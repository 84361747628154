"use client";

import * as React from "react";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { trpc } from "@/utils/trpc";
import type { WorksheetId } from "allgood-schema";
import { Divider } from "@mui/material";
import type { Worksheet } from "allgood-api/src/repos/worksheet.schema";
import { WorksheetCreateDialog } from "@/pages/worksheet-list/components/worksheet-create-dialog";

export interface WorksheetPopoverProps {
  selectedWorksheet?: Worksheet;
  anchorEl: null | Element;
  onChange?: (worksheetId: WorksheetId) => void;
  onClose?: () => void;
  open?: boolean;
}

export function WorksheetPopover({
  selectedWorksheet,
  anchorEl,
  onChange,
  onClose,
  open = false,
}: WorksheetPopoverProps): React.JSX.Element {
  const [worksheets] = trpc.worksheet.list.useSuspenseQuery({ filter: {} });

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={onClose}
        open={open}
        slotProps={{ paper: { sx: { width: "250px" } } }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {worksheets.records.map((worksheet) => (
          <MenuItem
            selected={selectedWorksheet?.id === worksheet?.id}
            key={worksheet.id}
            onClick={() => {
              onChange?.(worksheet.id);
            }}
          >
            {worksheet.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={() => setCreateDialogOpen(true)}>
          Create Worksheet
        </MenuItem>
      </Menu>
      <WorksheetCreateDialog
        open={createDialogOpen}
        setOpen={() => setCreateDialogOpen(false)}
        onSubmit={() => (onClose ? onClose() : null)}
      />
    </>
  );
}
