import type { Components } from "@mui/material/styles";

import type { Theme } from "../types";
import { agYellow } from "@/styles/theme/colors";

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    agYellow: true;
  }
}

export const MuiLinearProgress = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      borderRadius: "8px",
      overflow: "hidden",
      ...(ownerState.color === "agYellow" && {
        backgroundColor: agYellow["100"],
      }),
    }),
  },
} satisfies Components<Theme>["MuiLinearProgress"];
