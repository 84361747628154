"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import GlobalStyles from "@mui/material/GlobalStyles";
import { MainNav } from "./main-nav";
import type { NavItemConfig } from "@/types/nav";
import { navColorStyles } from "@/layouts/dashboard/layout/styles";

export interface HorizontalLayoutProps {
  children?: React.ReactNode;
  items: NavItemConfig[];
}

export function HorizontalLayout({
  children,
  items,
}: HorizontalLayoutProps): React.JSX.Element {
  const styles = navColorStyles["dark"]["discrete"];

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          body: {
            ...styles,
            "--MainNav-height": "73px",
            "--MainNav-zIndex": 1000,
            "--MobileNav-width": "240px",
            "--MobileNav-zIndex": 1100,
          },
        }}
      />
      <Box
        sx={{
          bgcolor: "var(--mui-palette-background-default)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          minHeight: "100%",
        }}
      >
        <MainNav items={items} />
        <Box
          component="main"
          sx={{
            "--Content-margin": "0 auto",
            "--Content-maxWidth": "var(--maxWidth-xl)",
            "--Content-paddingX": "24px",
            "--Content-paddingY": { xs: "24px", lg: "64px" },
            "--Content-padding":
              "var(--Content-paddingY) var(--Content-paddingX)",
            "--Content-width": "100%",
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            py: 9,
          }}
        >
          {children}
        </Box>
      </Box>
    </React.Fragment>
  );
}
